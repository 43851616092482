<template>
  <header class="flex flex-col">
    <div class="bg-gray-500 pt-[60px] pb-12"
         :class="[coverImage ? 'md:pb-64' : 'md:pb-20']"
    >
      <Container class="flex flex-col justify-center text-gray-100">
        <div class="flex justify-between w-full mt-12 mb-6 md:my-8">
          <BlogBackLink v-if="collection"
                        :collection="collection"
          />
          <JobBackLink v-else
                       default="/"
          />

          <SharePopover v-if="share"
                        title="Share this article"
          />
        </div>

        <!-- Tags -->
        <div v-if="categories && categories.length > 0"
             class="flex mb-6 md:mb-8 md:justify-center"
        >
          <div class="flex flex-wrap gap-6">
            <Tag v-for="category in categories"
                 :key="category.slug"
                 @click="() => $router.push({ path: blogPath, query: { category: category.slug } })"
            >
              {{ category.title }}
            </Tag>
          </div>
        </div>

        <h1 class="font-medium text-5xl md:text-7xl tracking-[-2px] md:text-center md:leading-[1.2] leading-[1.2]">
          {{ title }}
        </h1>

        <div class="max-w-3xl mt-6 text-xl md:mx-auto md:text-center md:mt-8">
          <div v-if="date">
            {{ new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) }}
          </div>

          <div v-if="author"
               class="mt-2"
          >
            {{ author }}
          </div>
        </div>
      </Container>
    </div>

    <div v-if="coverImage"
         class="bg-white md:px-5"
    >
      <div class="md:-mt-24 max-w-[1440px] mx-auto">
        <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
        <div class="aspect-w-21 aspect-h-10">
          <NuxtImg :src="coverImage"
                   :alt="title || ''"
                   class="md:rounded-3xl object-cover w-full"
                   sizes="xs:100vw sm:746px md:984px lg:1240px xl:1440px xxl:1440px"
                   preload
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import type { BlogCollection } from '~/enums';
import useBlogPath from '../../composables/blog-path';

type BlogHeaderType = {
  title: string;
  coverImage: string;
  author?: string;
  share?: boolean;
  date?: string;
  collection?: BlogCollection;
  categories?: {
    title: string;
    slug: string;
  }[];
};

const props = withDefaults(defineProps<BlogHeaderType>(), {
  author: '',
  share: true,
  date: '',
  collection: null,
  categories: null
});

const blogPath = useBlogPath({ collection: props.collection });
</script>
